<template>
  <div class="pa-2 mb-1">

    <!-- Accept vs Reject confirmation -->
    <transition>
      <div v-if="status === 'new'">
        <SlideButton ref="acceptance-button" @unlock-left="onSlideButtonResolved('left')"
          @unlock-right="onSlideButtonResolved('right')">
        </SlideButton>
      </div>
    </transition>

    <!-- Info & Reprint & Complete & Cancel actions -->
    <transition class="pa-2">
      <div v-if="status != 'new'" style="position: relative;">
        <v-row no-gutters>

          <v-col cols="12" v-if="!isRetail">
            <v-btn tile depressed color="teal lighten-2" width="100%" height="40" class="white--text"
              :disabled="isProcessing" @click="callCustomer">
              <div><v-icon>mdi-phone</v-icon>&nbsp;&nbsp;聯絡客人 {{ user.phone | toTwnPhoneString }}</div>
            </v-btn>
          </v-col>

          <v-col cols="3">
            <v-btn tile depressed color="grey" width="100%" height="64" class="white--text" :disabled="isProcessing"
              @click="showMoreInfoDialog">
              <div><v-icon>mdi-information</v-icon><br>更多</div>
            </v-btn>
          </v-col>

          <v-col cols="3">
            <v-btn tile depressed color="blue-grey lighten-1" width="100%" height="64" class="white--text"
              :disabled="isProcessing" @click="dialog.reprint.show = true">
              <div><v-icon>mdi-printer</v-icon><br>重印</div>
            </v-btn>
          </v-col>

          <v-col cols="3">
            <v-btn tile depressed color="light-blue" width="100%" height="64" class="white--text"
              :disabled="isProcessing || status != 'accepted' || pendingStatus.length > 0"
              @click="setOrderStatus('complete')">
              <div><v-icon>mdi-check</v-icon><br>完成</div>
            </v-btn>
          </v-col>

          <v-col cols="3">
            <v-btn tile depressed color="error" width="100%" height="64"
              :disabled="isProcessing || ['rejected', 'cancelled', 'lala_on_going', 'lala_picked_up', 'lala_completed'].indexOf(status) >= 0 || ['reject', 'cancel'].indexOf(pendingStatus) >= 0"
              @click="dialog.cancellation.show = true">
              <div><v-icon>mdi-cancel</v-icon><br>取消</div>
            </v-btn>
          </v-col>
        </v-row>

        <v-progress-linear indeterminate color="teal" v-show="isProcessing"></v-progress-linear>
      </div>
    </transition>

    <!-- Dialog - Cancellation confirm -->
    <v-dialog v-model="dialog.cancellation.show">
      <v-card>
        <v-card-title class="headline">將會取消訂單({{ mealTypeLiteral }} {{ orderNumber }})</v-card-title>
        <v-card-text>
          <!-- <span v-if="status == 'new'">請選擇取消接單的理由。</span> -->
          注意，此動作無法復原！您確定要取消此訂單？（如顧客已線上支付則會退費）
        </v-card-text>

        <v-list shaped>

          <v-list-item-group v-if="status == 'new'">
            <v-list-item @click="setOrderStatus('reject', 1001)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-timer-sand-empty</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>店家忙碌，無法接單</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setOrderStatus('reject', 1002)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-weather-night</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>即將打烊，無法接單</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setOrderStatus('reject', 1003)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-tray-alert</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>物料不足，無法接單</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="dialog.cancellation.show = false">
              <v-list-item-icon class="my-auto"><v-icon>mdi-arrow-left</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>不執行取消</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group v-else>
            <v-list-item @click="setOrderStatus('cancel')">
              <v-list-item-icon class="my-auto"><v-icon>mdi-cancel</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>取消訂單（不含理由）</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="setOrderStatus('cancel', 2001)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-timer-sand-empty</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>店家忙碌，無法處理</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setOrderStatus('cancel', 2002)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-weather-night</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>即將打烊，無法處理</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setOrderStatus('cancel', 2003)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-tray-alert</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>物料不足，無法處理</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setOrderStatus('cancel', 2004)">
              <v-list-item-icon class="my-auto"><v-icon>mdi-account-question</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>無外送人員，無法處理</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="requestDeliveryCancel" v-if="type === 'delivery'">
              <v-list-item-icon class="my-auto"><v-icon>mdi-moped</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>僅取消物流</v-list-item-title>
                <v-list-item-subtitle>取消媒合外送，改自行配送</v-list-item-subtitle>
                <v-list-item-subtitle>如外送員已媒合則無法取消</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="dialog.cancellation.show = false">
              <v-list-item-icon class="my-auto"><v-icon>mdi-arrow-left</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>不執行取消</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

        </v-list>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.disableCancelOrderPrompt.show" width="480px">
      <v-card>
        <v-card-title class="headline">
          因您有開通發票服務，取消訂單請統一至櫃檯點餐PDG Lite取消並作廢發票。
        </v-card-title>
      </v-card>
    </v-dialog>
    <!-- Dialog - More info -->
    <v-dialog v-model="dialog.info.show" width="480px">
      <v-card>
        <v-card-title class="headline">{{ mealTypeLiteral }} {{ orderNumber }}</v-card-title>
        <v-card-text>
          <!-- <div>訂單編號（ID）：{{ orderId }}</div>
          <div>訂購人LINE名稱：{{ user.name }}</div>
          <div v-if="type == 'delivery'">
            <div>外送地址：{{ user.delivery_address }}</div>
            <div>外送時間：{{ user.delivery_time }} 分鐘</div>
            <div>外送運費：${{ user.delivery_fee }}</div>
            <div>司機名稱：{{ dialog.info.driver.name }}</div>
            <div>司機車牌：{{ dialog.info.driver.plate }}</div>
            <div>司機電話：{{ dialog.info.driver.phone }}</div>
          </div> -->

          <v-simple-table dense>
            <template v-slot:default>
              <!-- <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Calories
                  </th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="item in dialog.info.items" :key="item.label">
                  <td>{{ item.label }}</td>
                  <td>{{ item.val }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

        <v-list shaped>
          <v-list-item-group>
            <v-list-item two-line @click="callCustomer">
              <v-list-item-icon class="my-auto"><v-icon>mdi-phone</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>聯絡訂購人</v-list-item-title>
                <v-list-item-subtitle>撥電話給 {{ user.phone | toTwnPhoneString }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line @click="callRecipient" v-if="isRetail && user.recipient && user.recipient.phone">
              <v-list-item-icon class="my-auto"><v-icon>mdi-phone</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>聯絡收件人</v-list-item-title>
                <v-list-item-subtitle>撥電話給 {{ user.recipient.phone | toTwnPhoneString }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions v-if="QRCodeHtmlContent">
          <v-row justify="center">
            <v-col cols="12" align-self="center" class="text-center">
              <div class="text-body-2 mb-1">訂單內容</div>
              <div v-html="QRCodeHtmlContent"></div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Reprint confirm -->
    <v-dialog v-model="dialog.reprint.show" width="360px">
      <v-card>
        <v-card-title class="headline">將會重印訂單({{ mealTypeLiteral }} {{ orderNumber }})</v-card-title>
        <v-card-text>
          您確定嗎？（請確認出單機／標籤機網路狀態正常）
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog.reprint.show = false">否</v-btn>
          <v-btn color="error" text @click="requestReprint">是</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Successful message snackbars -->
    <!-- <v-snackbar top color="success"
      v-for="(bar, action) in snackbar" :key="`success-${action}`"
      v-model="bar.success.show" >
      {{ mealTypeLiteral }} {{ orderNumber }} {{ bar.success.message }}
      <v-btn dark text @click="bar.success.show = false">Close</v-btn>
    </v-snackbar> -->

    <!-- Error message snackbars -->
    <!-- <v-snackbar top color="error"
      v-for="(bar, action) in snackbar" :key="`error-${action}`"
      v-model="bar.error.show" >
      {{ mealTypeLiteral }} {{ orderNumber }} {{ bar.error.message }}
      <v-btn dark text @click="bar.error.show = false">Close</v-btn>
    </v-snackbar> -->

  </div>
</template>

<script>
import SlideButton from '../components/SlideButton';
import { createQRCodeHtmlContent } from '../lib/order-actions'

export default {
  name: 'OrderControl',
  components: {
    SlideButton
  },
  props: {
    orderId: String,//FIXME: For some reason Vuexfire interprets this "id" as a string...
    status: String,
    orderNumber: String,
    type: String,
    user: {
      type: Object,
      default: () => {
        return { phone: '', name: '' };
      }
    }
  },

  mounted: async function () {
    if (this.orderId) {
      this.QRCodeHtmlContent = await createQRCodeHtmlContent(this.orderId);
    }
  },

  methods: {

    /// Order actions.

    async setOrderStatus(action, _reason) {
      console.log(action)
      if(action == 'cancel') {
        const getSession = localStorage.getItem('qcpos-session-user')
        const accountId = JSON.parse(getSession).shopId
        let shouldProceed = await new Promise((resolve, reject) => {
          this.$store.dispatch('checkInvoice',{accountId})
            .then(result => {
              if(result.invoiceData.isDdcInvoice) {
                this.dialog.disableCancelOrderPrompt.show = true
                return reject(false)
              } 
              if (['lala_on_going', 'lala_picked_up', 'lala_completed'].indexOf(this.status) >= 0) {
                this.$store.commit('notify', {
                  msg: `${this.mealTypeLiteral} ${this.orderNumber} 已經媒合物流，無法取消。`,
                  attrs: { color: 'warning' }
                });
                return reject(false)
              }
              resolve(true)
            })
        }).catch(() => false)
        if (!shouldProceed) return
      }
      this.isProcessing = true;
      this.dialog.cancellation.show = false;

      return this.$store.dispatch('updateOrderStatus', { orderId: this.orderId, status: action, reason: _reason })
        .then(result => {
          console.info(`Actively update the status of order ${this.orderId} successfully! (${action})`);
          this.pendingStatus = action;

          // this.snackbar[action].success.show = true;
          let alertColor = ['cancel', 'reject'].indexOf(action) >= 0 ? 'warning' : 'success';
          this.$store.commit('notify', {
            msg: `${this.mealTypeLiteral} ${this.orderNumber} ${this.snackbar[action].success.message}`,
            attrs: { color: alertColor }
          });
        })
        .catch(err => {
          console.error(`Failed to update status of order ${this.orderId}. (${action})`);
          //TODO: Show error snackbar.
          // this.snackbar[action].error.show = true;
          this.$store.commit('notify', {
            msg: `${this.mealTypeLiteral} ${this.orderNumber} ${this.snackbar[action].error.message}`,
            attrs: { color: 'error' }
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    requestReprint() {
      this.isProcessing = true;
      this.dialog.reprint.show = false;
      console.warn('requestReprint', this.orderId);

      return this.$store.dispatch('requestReprint', { orderId: this.orderId })//FIXME: 'orderId' format???
        .then(result => {
          // this.snackbar.reprint.success.show = true;
          this.$store.commit('notify', {
            msg: `${this.mealTypeLiteral} ${this.orderNumber} ${this.snackbar.reprint.success.message}`,
            attrs: { color: 'success' }
          });
        })
        .catch(err => {
          // this.snackbar.reprint.error.show = true;
          this.$store.commit('notify', {
            msg: `${this.mealTypeLiteral} ${this.orderNumber} ${this.snackbar.reprint.error.message}`,
            attrs: { color: 'error' }
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    requestDeliveryCancel() {
      //TODO: Block delivery cancellation if not allowed.
      if (['lala_on_going', 'lala_picked_up', 'lala_completed'].indexOf(this.status) >= 0) {
        this.$store.commit('notify', {
          msg: `${this.mealTypeLiteral} ${this.orderNumber} 已經媒合物流，無法取消。`,
          attrs: { color: 'warning' }
        });
        return;
      }

      this.isProcessing = true;
      this.dialog.cancellation.show = false;

      return this.$store.dispatch('requestDeliveryCancel', { orderId: this.orderId })//FIXME: 'orderId' format???
        .then(result => {
          // this.snackbar.deliveryCancel.success.show = true;
          this.$store.commit('notify', {
            msg: `${this.mealTypeLiteral} ${this.orderNumber} ${this.snackbar.deliveryCancel.success.message}`,
            attrs: { color: 'success' }
          });
        })
        .catch(err => {
          // this.snackbar.deliveryCancel.error.show = true;
          this.$store.commit('notify', {
            msg: `${this.mealTypeLiteral} ${this.orderNumber} ${this.snackbar.deliveryCancel.error.message}`,
            attrs: { color: 'error' }
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    /// Other utilities.

    showMoreInfoDialog() {
      // <!-- <div>訂單編號（ID）：{{ orderId }}</div>
      //     <div>訂購人LINE名稱：{{ user.name }}</div>
      //     <div v-if="type == 'delivery'">
      //       <div>外送地址：{{ user.delivery_address }}</div>
      //       <div>外送時間：{{ user.delivery_time }} 分鐘</div>
      //       <div>外送運費：${{ user.delivery_fee }}</div>
      //       <div>司機名稱：{{ dialog.info.driver.name }}</div>
      //       <div>司機車牌：{{ dialog.info.driver.plate }}</div>
      //       <div>司機電話：{{ dialog.info.driver.phone }}</div>
      //     </div> -->
      // Fill up the info entries.
      this.dialog.info.items = [
        { label: '訂單編號（ID）', val: this.orderId },
        { label: '訂購人LINE名稱', val: this.user.name },
        { label: '訂購人電話', val: this.user.phone }
      ];

      if (this.isRetail) {
        this.dialog.info.items.push(
          { label: '收件人名稱', val: this.user.recipient.name },
          { label: '收件人電話', val: this.user.recipient.phone }
        );
      }

      if (this.type == 'delivery') {
        this.dialog.info.items.push(
          { label: '外送地址', val: this.user.delivery_address },
          { label: '外送時間', val: `${this.user.delivery_time} 分鐘` },
          { label: '外送運費', val: `$${this.user.delivery_fee}` }
        );
        //TODO: Also show recipient's info if this is a retail-delivery case.
      }

      //TODO: If this is a delivery order, try to find its driver's info.
      if (this.type == 'delivery' && !this.dialog.info.driver.name.length) {
        this.$store.dispatch('fetchDeliveryDriverInfo', { orderId: this.orderId })
          .then(result => {
            // this.dialog.info.driver = result;
            this.dialog.info.items.push(
              { label: '司機名稱', val: result.driver.name },
              { label: '司機車牌', val: result.driver.plate },
              { label: '司機電話', val: result.driver.phone }
            );
          })
          .catch(err => {
            console.error(`[More info dialog] Cannot fetch info about the driver!`, err);
            this.dialog.info.items.push(
              { label: '-', val: '外送員司機資訊暫無法取得。' }
            );
          });
      }

      // Show the dialog.
      this.dialog.info.show = true;
    },

    callCustomer() {
      window.location.href = `tel:${this.user.phone}`;
    },
    callRecipient() {
      window.location.href = `tel:${this.user.recipient.phone}`;
    },

    /// Widget event handlers.

    onSlideButtonResolved(side) {
      if (side === 'left') {
        this.dialog.cancellation.isOrderAccepted = false;
        this.dialog.cancellation.show = true;
      }
      else {
        this.setOrderStatus('accept');
      }
    },

    resetSlideButton() {
      if (this.$refs['acceptance-button']) this.$refs['acceptance-button'].reset();
    }
  },

  data() {
    return {
      // isCompleted: false,
      isProcessing: false,
      pendingStatus: '',

      dialog: {
        info: {
          show: false,
          driver: { name: '', plate: '', phone: '' },
          items: []
        },
        cancellation: {
          show: false,
          isOrderAccepted: true
        },
        disableCancelOrderPrompt :{
          show: false,
        },
        reprint: {
          show: false
        }
      },

      snackbar: {
        accept: {
          success: {
            show: false,
            message: '已接單。'
          },
          error: {
            show: '',
            message: '無法接單，請確認網路狀態、稍候重試。'
          }
        },
        complete: {
          success: {
            show: false,
            message: '已完成。'
          },
          error: {
            show: '',
            message: '無法完成，請確認網路狀態、稍候重試。'
          }
        },
        cancel: {
          success: {
            show: false,
            message: '已取消。'
          },
          error: {
            show: false,
            message: '無法取消，請確認網路狀態、稍候重試。'
          }
        },
        reject: {
          success: {
            show: false,
            message: '已取消接單。'
          },
          error: {
            show: false,
            message: '無法取消接單，請確認網路狀態、稍候重試。'
          }
        },
        reprint: {
          success: {
            show: false,
            message: '已請求重新列印，請查看您的出單機。'
          },
          error: {
            show: '',
            message: '無法重印，請確認網路與出單機狀態、稍候重試。'
          }
        },
        deliveryCancel: {
          success: {
            show: false,
            message: '已請求取消物流。'
          },
          error: {
            show: '',
            message: '取消物流失敗。'
          }
        }
      },

      actionLiteral(_action) {
        return {
          'accept': '接單',
          'done': '完成',
          'cancel': '取消'
        }[_action];
      },
      QRCodeHtmlContent: "",
    };
  },

  computed: {
    mealTypeLiteral() {
      let mealTypeLabels = this.$store.getters.mealTypeLabels;
      return {
        'eat_in': mealTypeLabels[0],
        'to_go': mealTypeLabels[1],
        'delivery': mealTypeLabels[2],
        'takeaway': mealTypeLabels[3]
      }[this.type];
    },

    isRetail() {
      return this.$store.getters.isRetail;
    }
  },

  watch: {
    orderId(_id) {
      //NOTE: In TPOS, if orderId changed, reset flag variables.
      this.isProcessing = false;
      this.pendingStatus = '';
    },
    status(s) {
      console.warn(`OrderControl: Status of order ${this.orderId} has changed to ${s}.`);
      this.pendingStatus = '';
    },
    'dialog.cancellation.show': {
      handler(show) {
        if (show === false && !this.isProcessing)//Reset slide-button when aborting CANCEL request.
          this.resetSlideButton();
      }
    }
  },

  filters: {
    toTwnPhoneString: phone => {
      if (!phone) return '';
      if (phone.indexOf('+886') === 0)
        return '0' + phone.substring(4);
      if (phone.indexOf('886') === 0)
        return '0' + phone.substring(3);
      return phone;
    }
  }
}
</script>

<style scoped>
.v-leave {
  opacity: 1;
}

.v-leave-active {
  transition: opacity .5s
}

.v-leave-to {
  opacity: 0;
}

.v-enter {
  opacity: 0;
}

.v-enter-active {
  transition: opacity .5s
}

.v-enter-to {
  opacity: 1;
}

.floating-spinner {
  position: absolute;
  width: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}
</style>